import { clsx, type ClassValue } from "clsx"
import { extendTailwindMerge } from "tailwind-merge"
import { withFluid } from '@fluid-tailwind/tailwind-merge'


const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": [
        {
          "fluid-text": ["sm", "base", "lg", "xl", "2xl", "3xl", "4xl", "5xl", "6xl", "7xl", "8xl", "9xl",
          ],
        },
      ],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatColumnName(columnName: string): string {
  return columnName
    // Önce alt çizgileri boşluklara çevirir
    .replace(/_/g, ' ')
    // Ardından camelCase'i ayrı kelimelere böler
    .replace(/([A-Z])/g, ' $1')
    // İlk harfi büyük yapar
    .replace(/^./, str => str.toUpperCase())
    // Baştaki ve sondaki boşlukları temizler
    .trim()
    // Ardışık boşlukları tek boşluğa indirir
    .replace(/\s+/g, ' ');
}

export function generateSlug(text: string) {
  text = text.trim();
  text = text.toLowerCase();
  text = text.replace(/[^a-z0-9]/g, '-');
  text = text.replace(/\s+/g, '-');
  text = text.replace(/-+/g, '-');
  text = text.replace(/^-|-$/g, '');

  return text;

}