"use client";

import * as React from "react";
import { login } from "@/app/actions/auth";
import { cn } from "@/lib/utils";
import { CircleNotch } from "@phosphor-icons/react";
import { Button } from "@/components/elements/Button";
import { Input } from "@/components/elements/Form/Input";
import { Label } from "@/components/elements/Form/Label";
import { toast } from "sonner";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
  const [formState, formAction, pending] = React.useActionState(login, undefined);
  const [validationErrors, setValidationErrors] = React.useState<Record<string, string | undefined>>({});

  React.useEffect(() => {
    if (formState?.errors) {
      setValidationErrors(formState?.errors as Record<string, string | undefined>);
    } else {
      setValidationErrors({});
    }
  }, [formState]);

  const [formValues, setFormValues] = React.useState({
    email: "",
    password: "",
  });

  React.useEffect(() => {
    if (formState?.errors) {
      if ("serverErrors" in formState.errors) {
        Object.values(formState.errors.serverErrors || {})
          .flat()
          .forEach((error) => {
            toast.error(error);
          });
      }
      if (
        "UNKNOWN_ERROR" in formState.errors &&
        formState.errors.UNKNOWN_ERROR
      ) {
        toast.error(formState.errors.UNKNOWN_ERROR);
      }
    } 
  }, [formState]);

  const handleInputChange = (field: "email" | "password", value: string) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
    setValidationErrors((prev) => ({ ...prev, [field]: undefined }));
  };

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <form action={formAction}>
        <div className="flex flex-col items-center justify-center w-full gap-4">
          <div className="flex flex-col items-start gap-1 w-full">
            <Label
              htmlFor="email"
              className={
                validationErrors.email ? "text-red-500" : ""
              }
            >
              Email
            </Label>
            <Input
              id="email"
              name="email"
              placeholder="name@example.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              wrapperClassName="w-full"
              variant={
                validationErrors.email ? "error" : "default"
              }
              value={formValues.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
            />
            {validationErrors.email && <p className="text-red-500 text-sm">{validationErrors.email}</p>}
          </div>
          <div className="flex flex-col items-start gap-1 w-full">
            <Label
              htmlFor="password"
              className={
                validationErrors.password ? "text-red-500" : ""
              }
            >
              Password
            </Label>
            <Input
              id="password"
              name="password"
              placeholder="********"
              type="password"
              autoCapitalize="none"
              autoComplete="current-password"
              autoCorrect="off"
              wrapperClassName="w-full"
              variant={
                validationErrors.password ? "error" : "default"
              }
              value={formValues.password}
              onChange={(e) => handleInputChange("password", e.target.value)}
            />
            {validationErrors.password && <p className="text-red-500 text-sm">{validationErrors.password}</p>}
          </div>
          <Button disabled={pending} className="w-full" type="submit">
            {pending && <CircleNotch className="mr-2 h-4 w-4 animate-spin" />}
            Login with Email
          </Button>
        </div>
      </form>
    </div>
  );
}
