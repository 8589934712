import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import Link from "next/link";
import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center vertical-middle whitespace-nowrap rounded-md text-sm font-medium transition-colors focus:outline-none disabled:pointer-events-none disabled:opacity-50 cursor-pointer outline-none border shadow-none",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground hover:bg-primary/90 border-primary",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90 border-destructive",
        outline:
          "border border-foreground/50 bg-background hover:bg-accent hover:text-accent-foreground",
        outlinePrimary: "border border-primary bg-none text-foreground hover:bg-primary hover:text-background",
        secondary:
          "border-secondary bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost:
          "border-transparent hover:bg-muted hover:text-secondary-foreground data-[state=active]:bg-muted data-[state=open]:bg-muted data-[state=open]:text-secondary-foreground",
        link: "text-foreground underline-offset-4 hover:underline border-transparent",
        accent:
          "bg-accent text-accent-foreground hover:bg-accent/80 border-accent",
        bw: "bg-foreground text-background hover:bg-foreground/80 border-foreground",
        menu: "border-transparent hover:bg-primary/90 hover:text-background dark:hover:bg-primary/10 dark:hover:text-primary data-[state=active]:bg-primary/90 data-[state=active]:text-background dark:data-[state=active]:bg-primary/10 dark:data-[state=active]:text-primary",
      },
      size: {
        default: "h-11 px-5 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-12 rounded-md px-8",
        icon: "h-11 w-11 min-w-11",
        iconMd: "h-12 w-12 min-w-12",
        avatar: "h-11 w-11",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "size">,
    VariantProps<typeof buttonVariants> {
  as?: "button" | "link" | "a";
  href?: string;
  asChild?: boolean;
}

const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(
  (
    {
      className,
      variant,
      size,
      as = "button",
      asChild = false,
      href,
      ...props
    },
    ref
  ) => {
    let Comp: React.ElementType = "button";

    if (asChild) {
      Comp = Slot;
    } else if (as === "link" && href) {
      Comp = Link;
    } else if (as === "a" && href) {
      Comp = "a";
    }

    const computedClassNames = cn(
      buttonVariants({
        variant,
        size: size as "default" | "sm" | "lg" | "icon",
      }),
      className
    );

    return (
      <Comp
        className={computedClassNames || undefined}
        ref={ref as any}
        href={href}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
