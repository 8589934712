"use client";
import * as React from "react";
import { cva, VariantProps } from "class-variance-authority";
import "@styles/eye-animation.css";
import { cn } from "@/lib/utils";

const inputVariants = cva(
  "flex w-full rounded-md border-2 text-sm ring-offset-muted file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-input border border-transparent focus-visible:ring-border focus-visible:border-border",
        error:
          "border-destructive bg-red-50 focus-visible:ring-destructive text-background",
      },
      size: {
        default: "h-11 px-3 py-2",
        sm: "h-9 px-2 py-1.5",
        lg: "h-12 px-4 py-3",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  variant?: "default" | "error";
  size?: "default" | "sm" | "lg";
  wrapperClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, wrapperClassName, type, variant, size, ...props }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

    const togglePasswordVisibility = () => {
      setIsPasswordVisible((prev) => !prev);
    };

    return (
      <div className={cn("relative z-[1]", wrapperClassName)}>
        <input
          type={type === "password" && isPasswordVisible ? "text" : type}
          className={cn(inputVariants({ variant, size }), "group", className)}
          ref={ref}
          {...props}
        />
        {type === "password" && (
          <div
            id="wrapper"
            className={cn(
              "absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer flex items-center",
              isPasswordVisible ? "eye-open" : "eye-close"
            )}
            onClick={togglePasswordVisibility}
          >
            <div id="icon-eye" className="w-6 h-6 text-foreground">
              <svg
                viewBox="-20 -200 320 400"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                className={cn(
                  "w-full h-full transition-all duration-200 ease-in-out",
                  variant === "error" ? "text-destructive" : "text-foreground"
                )}
              >
                <g id="eye" strokeWidth="25" fill="none">
                  <g
                    id="eye-lashes"
                    stroke="currentColor"
                    className="[&.eye-open]:animate-scaleUp [&.eye-close]:animate-scaleDown"
                  >
                    <line x1="140" x2="140" y1="90" y2="180" />
                    <line x1="70" x2="10" y1="60" y2="140" />
                    <line x1="210" x2="270" y1="60" y2="140" />
                  </g>
                  <path
                    id="eye-bottom"
                    d="m0,0q140,190 280,0"
                    stroke="currentColor"
                  />
                  <path
                    id="eye-top"
                    d="m0,0q140,190 280,0"
                    stroke="currentColor"
                    className="[&.eye-open]:rotate-x-180 transition-all duration-200 ease-in-out"
                  />
                  <circle
                    id="eye-pupil"
                    cx="140"
                    cy="0"
                    r="40"
                    fill="currentColor"
                    stroke="none"
                    className={cn("transition-opacity duration-200", {
                      "opacity-100": isPasswordVisible,
                      "opacity-0": !isPasswordVisible,
                    })}
                  />
                </g>
              </svg>
            </div>
          </div>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

export { Input };
